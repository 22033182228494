
/********************** Contactos **********************/

.contactos{
    margin-top: 3rem;
}

.contactos .titulo {
    font-size: 2.5rem;
    font-weight: normal;
    margin-bottom: 4rem;
    color: var(--light-color-white);
    text-transform: none;
}


.contactos .site-contacto{
    font-weight: bold;
    color: var(--black);
    font-style: normal;
    font-size: 2.3rem;
}


.icons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 6rem 0;
}
.icons a{
    margin: 5rem 3rem;
    text-decoration: none;
    color: #fff;
    display: block;
    position: relative;
}
.icons a .layer{
    width: 7rem;
    height: 7rem;
    transition: transform 0.3s;
}
.icons a:hover .layer{
    transform: rotate(-35deg) skew(20deg);
}
.icons a .layer span{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 3px solid #fff;
    border-radius: 5px;
    transition: all 0.3s;
}
.icons a .layer span.fab{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    line-height: 55px;
}
.icons a:hover .layer span:nth-child(1){
    opacity: 0.2;
}
.icons a:hover .layer span:nth-child(2){
    opacity: 0.4;
    transform: translate(5px, -5px);
}
.icons a:hover .layer span:nth-child(3){
    opacity: 0.6;
    transform: translate(10px, -10px);
}
.icons a:hover .layer span:nth-child(4){
    opacity: 0.8;
    transform: translate(15px, -15px);
}
.icons a:hover .layer span:nth-child(5){
    opacity: 1;
    transform: translate(20px, -20px);
}
.icons a:nth-child(1) .layer span,
.icons a:nth-child(1) .text{
    color: #ff0000;
    border-color: #ff0000;
}
.icons a:nth-child(2) .layer span,
.icons a:nth-child(2) .text{
    color: #4fc35b;
    border-color: #4fc35b;
}
.icons a:nth-child(3) .layer span,
.icons a:nth-child(3) .text{
    color: #E1306C;
    border-color: #E1306C;
}
.icons a:nth-child(4) .layer span,
.icons a:nth-child(4) .text{
    color: #1DA1F2;
    border-color: #1DA1F2;
}
.icons a:nth-child(5) .layer span,
.icons a:nth-child(5) .text{
    color: #2867B2;
    border-color: #2867B2;
}
.icons a:nth-child(6) .layer span,
.icons a:nth-child(6) .text{
    color: #000;
    border-color: #000;
}

.icons a:hover:nth-child(1) .layer span{
    box-shadow: -1px 1px 3px #ff0000;
}
.icons a:hover:nth-child(2) .layer span{
    box-shadow: -1px 1px 3px #4fc35b;
}
.icons a:hover:nth-child(3) .layer span{
    box-shadow: -1px 1px 3px #E1306C;
}
.icons a:hover:nth-child(4) .layer span{
    box-shadow: -1px 1px 3px #1DA1F2;
}
.icons a:hover:nth-child(5) .layer span{
    box-shadow: -1px 1px 3px #4267B2;
}
.icons a .text{
    position: absolute;
    left: 50%;
    bottom: -5px;
    opacity: 0;
    font-size: 2rem;
    font-weight: 600;
    transform: translateX(-50%);
    transition: bottom 0.3s ease, opacity 0.3s ease;
}
.icons a:hover .text{
    bottom: -35px;
    opacity: 1;
}

/********************** Media Q **********************/

@media (max-width: 990px) {
    .icons a .layer span.fab{
        padding-top: 0;
    }
}

@media (max-width: 760px) {
    .icons a .text{
        bottom: -35px;
        opacity: 1;
    }
}

@media (max-width: 450px) {
    .icons a{
        margin: 5rem 2rem;
    }
}